import React, {useEffect, useState} from 'react';
import axios from "axios";
function ScreenFiveSix(props) {
    const [userName, setUserName] = useState('')
    const [userAge, setUserAge] = useState('')
    const [userCountry, setUserCountry] = useState('')
    const [photo, setPhoto] = useState('')
    const [content, setContent] = useState([])
    function like () {
        const user = content.pop()
        setUserName(user.name)
        setUserAge(user.age)
        setUserCountry(user.country)
        setPhoto('https://tgdating.top/photos/' + user.photo.split('/').pop())
    }

    function dislike () {
        const user = content.pop()
        setUserName(user.name)
        setUserAge(user.age)
        setUserCountry(user.country)
        setPhoto('https://tgdating.top/photos/' + user.photo.split('/').pop())
    }

    useEffect(() => {
        const preloader = document.getElementById("preloaderd");

        const setPreloaderTransform = (value) => preloader.style.transform = `translateY(${value})`;

        // Скрываем прелоадер после загрузки страницы
        setTimeout(() => setPreloaderTransform('-100%'), 500);
        let id = ''
        /*global Telegram*/
        if (Telegram.WebApp.initDataUnsafe.user) {
            id = Telegram.WebApp.initDataUnsafe.user.id
        } else {
            id = '12345'
        }
        axios.get('https://tgdating.top/user/' + id).then(response => {


            const data = response.data
            axios.get('https://tgdating.top/users/search', { params : {country : data.country, gender : data.lookingFor}}).then(
                response => {

                    const user = response.data.content.pop()
                    setContent(content => ({...content} ,response.data.content))
                     setUserName(user.name)
                     setUserAge(user.age)
                     setUserCountry(user.country)
                     setPhoto('https://tgdating.top/photos/' + user.photo.split('/').pop())
                }
            )

        }).catch()

    }, []);
    return (
        <div>
            <div id="preloaderd">
                <div className="logo-container">
                    <div className="h1">TG Dating</div>
                </div>
            </div>

            <div className="app-wrapper">

                <section className="section app-header">
                    <div className="container">
                        <div className="header-relative-wrapper">
                            <div className="row header-row">
                                <div className="col-2">
                                    <a href="/screenSeven" className="header-btn btn btn-primary">
                                        <i className="bi bi-person-circle"></i>
                                    </a>
                                </div>
                                <div className="col-8">
                                    <div className="header-logo">TG Dating</div>
                                </div>
                                <div className="col-2">
                                    <button type="button" className="header-btn btn btn-primary"
                                            data-bs-toggle="offcanvas" data-bs-target="#offcanvasFilter"
                                            aria-controls="offcanvasFilter">
                                        <i className="bi bi-sliders"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <div className="offcanvas offcanvas-top text-bg-dark offcanvas-filter" tabIndex="-1"
                     id="offcanvasFilter">
                    <div className="offcanvas-header">
                        <button type="button" className="btn-close btn-close-white" data-bs-dismiss="offcanvas"
                                aria-label="Close"></button>
                    </div>
                    <div className="offcanvas-body">
                        <form action="#">
                            <div className="d-flex flex-column gap-2 mb-4">
                                <div>
                                    <div className="text-muted mb-1">Где ищем?</div>
                                    <select className="form-select">
                                        <option defaultValue>Страна</option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                        <option value="3">Three</option>
                                    </select>
                                </div>
                                <div>
                                    <div className="text-muted mb-1">Кого ищем?</div>
                                    <div className="row">
                                        <div className="col-6">
                                            <div className="select-gender select-option">
                                                <label htmlFor="male">
                                                    <i className="bi bi-person-standing"></i>
                                                    <input type="radio" id="male" name="gender" value="male"/>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="select-gender select-option">
                                                <label htmlFor="female">
                                                    <i className="bi bi-person-standing-dress"></i>
                                                    <input type="radio" id="female" name="gender" value="female"/>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="text-muted mb-1">Возраст</div>
                                    <div className="row gy-3">
                                        <div className="col-6">
                                            <input type="text" className="form-control" id="age-input-min"/>
                                        </div>
                                        <div className="col-6">
                                            <input type="text" className="form-control" id="age-input-max"/>
                                        </div>
                                        <div className="col">
                                            <div id="ageRangeSlider"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <button type="submit" className="btn btn-primary w-100">Сохранить</button>
                        </form>
                    </div>
                </div>

                <section className="section app-body">
                    <div className="container">
                        <div className="position-relative">
                            <div className="card card-profile">
                                <div className="card-img-wrapper">
                                    <img className="card-img-top" src={photo} alt=""/>
                                </div>
                                <div className="card-body">
                                    <div className="h4 card-title">{userName}, {userAge}</div>
                                    <div className="card-country">{userCountry}</div>
                                </div>
                                <div className="card-footer">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <button type="button" onClick={dislike} className="btn btn-primary btn-icon rounded-circle">
                                            <i className="bi bi-x-lg"></i>
                                        </button>
                                        <button type="button" onClick={like} className="btn btn-secondary btn-icon rounded-circle">
                                            <i className="bi bi-heart-fill"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


            </div>
        </div>
    );
}

export default ScreenFiveSix;