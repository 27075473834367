import React, {useEffect} from 'react';
import ScreenFour from "./ScreenFour";
import axios from "axios";
import {useNavigate} from "react-router-dom";

function LoginScreen(props) {
    useEffect(() => {
        const preloader = document.getElementById("preloadert");

        const setPreloaderTransform = (value) => preloader.style.transform = `translateY(${value})`;

        // Скрываем прелоадер после загрузки страницы
        setTimeout(() => setPreloaderTransform('-100%'), 500);

    }, []);
    const navigate = useNavigate()
    function postData(formData) {
        const name = formData.get('userName')
        const age = formData.get('userAge')
        const country = formData.get('userCountry')
        const lookingFor = formData.get('lookingFor')
        let id = ''
        /*global Telegram*/
        if (Telegram.WebApp.initDataUnsafe.user) {
            id = Telegram.WebApp.initDataUnsafe.user.id
        } else {
             id = '12345'
        }
        axios.post('https://tgdating.top/save', {telegramUserId : id, name : name, age : age, country : country, lookingFor : lookingFor })
            .then(  navigate(`/screenFour`)).catch()
    }
    return (
        <div id="LoginScreen">
            <div id="preloadert">
                <div className="logo-container">
                    <div className="h1">TG Dating</div>
                </div>
            </div>


            <div className="app-wrapper">

                <section className="section app-body">
                    <div className="position-relative d-flex flex-column justify-content-center flex-fill">
                        <div className="container small-container">
                            <div className="text-center">
                                <div className="mb-3">Заполните данные о себе:</div>
                                <form  action={postData}>
                                    <div className="d-flex flex-column gap-2 mb-4">
                                        <div className="position-relative">
                                            <input type="text" name="userName" className="form-control" placeholder="Имя"/>

                                            <div className="form-invalid" style={{display: "none"}}>Введите имя</div>
                                        </div>
                                        <div className="position-relative">
                                            <input type="text"  name="userAge" className="form-control" placeholder="Возраст"/>
                                            <div className="form-invalid" style={{display: "none"}}>Введите возраст
                                            </div>
                                        </div>
                                        <div className="position-relative">
                                            <select name="userCountry" className="form-select">
                                                <option defaultValue>Ваша страна</option>
                                                <option value="1">One</option>
                                                <option value="2">Two</option>
                                                <option value="3">Three</option>
                                            </select>

                                            <div className="form-invalid" style={{display: "none"}}>Укажите вашу
                                                страну
                                            </div>
                                        </div>
                                        <div className="position-relative">
                                            <select name="lookingFor" className="form-select">
                                                <option defaultValue>Кого ищем?</option>
                                                <option value="1">One</option>
                                                <option value="2">Two</option>
                                            </select>
                                            <div className="form-invalid" style={{display: "none"}}>Укажите кого ищете
                                            </div>
                                        </div>
                                    </div>
                                    <button type="submit" className="btn btn-primary w-100">Далее</button>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="app-copyright">
                        <div className="container">
                            <div className="text-muted">Создавая аккаунт, Вы соглашаетесь с <a href="#">правилами
                                предоставления
                                услуг</a> на сервисе
                            </div>
                        </div>
                    </div>
                </section>


            </div>
        </div>

    );
}

export default LoginScreen;