import React, {useEffect} from 'react';
import profile from './img/profile.jpg'
function ScreenSeven(props) {
    useEffect(() => {
        const preloader = document.getElementById("preloaderw");

        const setPreloaderTransform = (value) => preloader.style.transform = `translateY(${value})`;

        // Скрываем прелоадер после загрузки страницы
        setTimeout(() => setPreloaderTransform('-100%'), 500);

    }, []);
    return (
        <div>
            <div id="preloaderw">
                <div className="logo-container">
                    <div className="h1">TG Dating</div>
                </div>
            </div>

            <div className="app-wrapper">

                <section className="section app-header">
                    <div className="container">
                        <div className="header-relative-wrapper">
                            <div className="row header-row">
                                <div className="col-2">
                                    <a href="/screenFiveSix" className="header-btn btn btn-primary">
                                        <i className="bi bi-arrow-left"></i>
                                    </a>
                                </div>
                                <div className="col-8">
                                    <div className="header-logo">TG Dating</div>
                                </div>
                                <div className="col-2">
                                    <a href="/screenFour" className="header-btn btn btn-primary">
                                        <i className="bi bi-gear-fill"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="section app-body">
                    <div className="container">
                        <div className="position-relative d-flex gap-2 flex-column">
                            <div className="d-flex justify-content-center">
                                <div className="profile-img-wrapper">
                                    <img src={profile} alt=""/>
                                </div>
                            </div>
                            <div className="text-center mb-3">
                                <div className="h4 profile-title">NameHolder, 22</div>
                                <div className="profile-country">CountryHolder</div>
                            </div>
                            <div className="profile-btn-group">
                                <div className="row gx-2">
                                    <div className="col-6">
                                        <a href="/screenNine" className="btn btn-primary fs-6 px-1 w-100">
                                            <div className="fs-3">
                                                <i className="bi bi-heart-fill"></i>
                                            </div>
                                            <div>
                                                Мои лайки
                                                <div className="profile-like-value">
                                                    (<span>987</span>)
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                    <div className="col-6">
                                        <a href="/screenEight" className="btn btn-primary fs-6 px-1 w-100">
                                            <div className="fs-3">
                                                <i className="bi bi-chat-text-fill"></i>
                                            </div>
                                            <div>
                                                Мои чаты
                                                <div className="profile-chats-value">
                                                    (<span>56</span>)
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


            </div></div>
    );
}

export default ScreenSeven;