import React, {useEffect} from 'react';
import profile from './img/profile.jpg'
function ScreenTen(props) {
    useEffect(() => {
        const preloader = document.getElementById("preloaderz");

        const setPreloaderTransform = (value) => preloader.style.transform = `translateY(${value})`;

        // Скрываем прелоадер после загрузки страницы
        setTimeout(() => setPreloaderTransform('-100%'), 500);

    }, []);
    return (
        <div>
            <div id="preloaderz">
                <div className="logo-container">
                    <div className="h1">TG Dating</div>
                </div>
            </div>

            <div className="app-wrapper">

                <section className="section app-header">
                    <div className="container">
                        <div className="header-relative-wrapper">
                            <div className="row header-row">
                                <div className="col-2">
                                    <a href="/screenNine" className="header-btn btn btn-primary">
                                        <i className="bi bi-arrow-left"></i>
                                    </a>
                                </div>
                                <div className="col-8">
                                    <div className="header-logo">TG Dating</div>
                                </div>
                                <div className="col-2">
                                    <a href="/screenFour" className="header-btn btn btn-primary">
                                        <i className="bi bi-gear-fill"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="section app-body">
                    <div className="container">
                        <div className="position-relative d-flex gap-2 flex-column">
                            <div className="d-flex justify-content-center">
                                <div className="profile-img-wrapper">
                                    <img src={profile} alt=""/>
                                </div>
                            </div>
                            <div className="text-center mb-3">
                                <div className="h4 profile-title">NameHolder, 22</div>
                                <div className="profile-country">CountryHolder</div>
                            </div>
                            <div className="d-flex align-items-center justify-content-between small-container w-100 mx-auto">
                                <button type="button" className="btn btn-primary btn-icon rounded-circle">
                                    <i className="bi bi-x-lg"></i>
                                </button>
                                <button type="button" className="btn btn-secondary btn-icon rounded-circle">
                                    <i className="bi bi-heart-fill"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </section>


            </div></div>
    );
}

export default ScreenTen;