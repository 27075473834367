import React, {useEffect} from 'react';
import profile from './img/profile.jpg'

function ScreenEleven(props) {
    useEffect(() => {
        const preloader = document.getElementById("preloaderv");

        const setPreloaderTransform = (value) => preloader.style.transform = `translateY(${value})`;

        // Скрываем прелоадер после загрузки страницы
        setTimeout(() => setPreloaderTransform('-100%'), 500);

    }, []);
    return (
        <div>
            <div id="preloaderv">
                <div className="logo-container">
                    <div className="h1">TG Dating</div>
                </div>
            </div>

            <div className="app-wrapper">

                <section className="section app-header app-chat-header">
                    <div className="container">
                        <div className="header-relative-wrapper">
                            <div className="row header-row">
                                <div className="col-2">
                                    <a href="/screenEight" className="header-btn btn btn-primary">
                                        <i className="bi bi-arrow-left"></i>
                                    </a>
                                </div>
                                <div className="col-8">
                                    <div className="chat-member">
                                        <div className="d-flex gap-3 align-items-center">
                                            <div className="chat-member__avatar">
                                                <img src={profile} alt=""/>
                                            </div>
                                            <div>
                                                <div className="chat-member__name">NameHolder</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-2"></div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="section app-body app-chat-body">
                    <div className="position-relative">
                        <div className="container">
                            <div className="d-flex gap-2 flex-column">
                                <div className="chat-box-area">
                                    <div className="chat-content">
                                        <div className="message-item">
                                            <div className="bubble">Hi Richard , thanks for adding me</div>
                                            <div className="message-time">08:35</div>
                                        </div>
                                    </div>
                                    <div className="chat-content user">
                                        <div className="message-item">
                                            <div className="bubble">Hi Miselia , your welcome , nice to meet you too
                                            </div>
                                            <div className="message-time">08:40</div>
                                        </div>
                                    </div>
                                    <div className="chat-content">
                                        <div className="message-item">
                                            <div className="bubble">Hi Richard , thanks for adding me</div>
                                            <div className="message-time">08:35</div>
                                        </div>
                                    </div>
                                    <div className="chat-content user">
                                        <div className="message-item">
                                            <div className="bubble">Hi Miselia , your welcome , nice to meet you too
                                            </div>
                                            <div className="message-time">08:40</div>
                                        </div>
                                    </div>
                                    <div className="chat-content">
                                        <div className="message-item">
                                            <div className="bubble">Hi Richard , thanks for adding me</div>
                                            <div className="message-time">08:35</div>
                                        </div>
                                    </div>
                                    <div className="chat-content user">
                                        <div className="message-item">
                                            <div className="bubble">Hi Miselia , your welcome , nice to meet you too
                                            </div>
                                            <div className="message-time">08:40</div>
                                        </div>
                                    </div>
                                    <div className="chat-content">
                                        <div className="message-item">
                                            <div className="bubble">Hi Richard , thanks for adding me</div>
                                            <div className="message-time">08:35</div>
                                        </div>
                                    </div>
                                    <div className="chat-content user">
                                        <div className="message-item">
                                            <div className="bubble">Hi Miselia , your welcome , nice to meet you too
                                            </div>
                                            <div className="message-time">08:40</div>
                                        </div>
                                    </div>
                                    <div className="chat-content">
                                        <div className="message-item">
                                            <div className="bubble">Hi Richard , thanks for adding me</div>
                                            <div className="message-time">08:35</div>
                                        </div>
                                    </div>
                                    <div className="chat-content">
                                        <div className="message-item">
                                            <div className="bubble">Hi Richard , thanks for adding me</div>
                                            <div className="message-time">08:35</div>
                                        </div>
                                    </div>
                                    <div className="chat-content user">
                                        <div className="message-item">
                                            <div className="bubble">Hi Miselia , your welcome , nice to meet you too
                                            </div>
                                            <div className="message-time">08:40</div>
                                        </div>
                                    </div>
                                    <div className="chat-content user">
                                        <div className="message-item">
                                            <div className="bubble">Hi Miselia , your welcome , nice to meet you too
                                            </div>
                                            <div className="message-time">08:40</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="section app-chat-field">
                    <div className="container">
                        <form action="#">
                            <div className="d-flex gap-2 align-items-center">
                                <input type="text" className="form-control"/>
                                <button type="submit" className="btn btn-secondary border-0 py-1 px-2">
                                    <i className="bi bi-send"></i>
                                </button>
                            </div>
                        </form>
                    </div>
                </section>


            </div>
        </div>
    );
}

export default ScreenEleven;