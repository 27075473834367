import logo from './logo.svg';
import './App.css';
import {useEffect} from "react";
import Header from "./Header";
import {Route, BrowserRouter as Router, Routes} from "react-router-dom";
import LoginScreen from "./LoginScreen";
import StartScreen from "./StartScreen";
import ScreenFour from "./ScreenFour";
import ScreenFiveSix from "./ScreenFiveSix";
import ScreenSeven from "./ScreenSeven";
import ScreenNine from "./ScreenNine";
import ScreenEight from "./ScreenEight";
import ScreenTen from "./ScreenTen";
import ScreenEleven from "./ScreenEleven";

function App() {
    useEffect (()=> {
        const preloader = document.getElementById("preloader");

        const setPreloaderTransform = (value) => preloader.style.transform = `translateY(${value})`;

        // Скрываем прелоадер после загрузки страницы
         setTimeout(() => setPreloaderTransform('-100%'), 500);

        document.querySelectorAll('.select-option').forEach(option => {
            option.addEventListener('click', () => {
                const radioInput = option.querySelector('input[type="radio"]');

                if (!radioInput.checked) {
                    document.querySelectorAll('.select-option.option-selected').forEach(it => it.classList.remove('option-selected'));
                    radioInput.checked = true;
                    option.classList.add('option-selected');
                }
            });
        });

    }, [])
    return (
        <div className="App">
            <div id="preloader">
                <div className="logo-container">
                    <div className="h1">TG Dating</div>
                </div>
            </div>

            <div className="app-wrapper">

                <Router>
                    <Routes>

                        <Route path={'/'} element={<StartScreen/>}/>
                        <Route path={'/login'} element={<LoginScreen/>}/>
                        <Route path={'/screenFour'} element={<ScreenFour/>}/>
                        <Route path={'/screenFiveSix'} element={<ScreenFiveSix/>}/>
                        <Route path={'/screenSeven'} element={<ScreenSeven/>}/>
                        <Route path={'/screenNine'} element={<ScreenNine/>}/>
                        <Route path={'/screenEight'} element={<ScreenEight/>}/>
                        <Route path={'/screenTen'} element={<ScreenTen/>}/>
                        <Route path={'/screenEleven'} element={<ScreenEleven/>}/>
                    </Routes>
                </Router>



            </div>
        </div>
    );
}

export default App;
